import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setTitle } from "../page-title/pageTitleSlice";
import styles from "./SearchResults.module.css";
import { selectSearchResults } from "./searchResultsSlice";

const SearchResults: React.FC = () => {
  const results = useAppSelector(selectSearchResults);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setTitle("Search Results"));
  }, [dispatch]);

  return (
    <div>
      {results.length === 0 ? (
        <div className={styles.empty}>No search results found.</div>
      ) : (
        results.map((result, key) => {
          return (
            <div className={styles.result} key={key}>
              <div className={styles.title}>
                <Link to={`/data/${result.name}`}>
                  {key + 1}. {result.name}
                </Link>
              </div>
              <div className={styles.remark}>{result.item.remark}</div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default SearchResults;
