import React, { useEffect } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import NotFound from "../not-found/NotFound";
import { setTitle } from "../page-title/pageTitleSlice";
import styles from "./Help.module.css";

const HelpIndex = () => {
  const title = "Help";
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setTitle(title));
  }, [dispatch, title]);
  return (
    <ul className={styles.index}>
      <li>
        <Link to="/help/search/1">Search datasets on your browser</Link>
      </li>
      <li>
        <Link to="/help/dataset">Downloadable dataset format</Link>
      </li>
      <li>
        <Link to="/help/viewer">3D Viewer Mannual</Link>
      </li>
    </ul>
  );
};

const HelpSearchLinks = (
  <div className={styles.search_links}>
    <ul>
      <li>
        <Link to="/help/search/1">Step 1</Link>
      </li>
      <li>
        <Link to="/help/search/2">Step 2</Link>
      </li>
      <li>
        <Link to="/help/search/3">Step 3</Link>
      </li>
      <li>
        <Link to="/help/search/4">Step 4</Link>
      </li>
      <li>
        <Link to="/help/search/5">Step 5</Link>
      </li>
    </ul>
  </div>
);

const HelpSearch1 = () => {
  const title = "Help - Search dataset - Step 1";
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setTitle(title));
  }, [dispatch, title]);
  return (
    <div>
      {HelpSearchLinks}
      <div className={styles.title}>Step 1</div>
      <p>Click the "Search" link above.</p>
      <div className={styles.figure}>
        <img src="/images/help-search1.jpg" alt={title} />
      </div>
    </div>
  );
};

const HelpSearch2 = () => {
  const title = "Help - Search dataset - Step 2";
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setTitle(title));
  }, [dispatch, title]);
  return (
    <div>
      {HelpSearchLinks}
      <div className={styles.title}>Step 2</div>
      <p>Set parameter value and/or chose options.</p>
      <div className={styles.figure}>
        <img src="/images/help-search2.jpg" alt={title} />
      </div>
    </div>
  );
};

const HelpSearch3 = () => {
  const title = "Help - Search dataset - Step 3";
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setTitle(title));
  }, [dispatch, title]);
  return (
    <div>
      {HelpSearchLinks}
      <div className={styles.title}>Step 3</div>
      <p>Click the "Search" button.</p>
      <div className={styles.figure}>
        <img src="/images/help-search3.jpg" alt={title} />
      </div>
    </div>
  );
};

const HelpSearch4 = () => {
  const title = "Help - Search dataset - Step 4";
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setTitle(title));
  }, [dispatch, title]);
  return (
    <div>
      {HelpSearchLinks}
      <div className={styles.title}>Step 4</div>
      <p>Chose datasets that you want to download. Click dataset ID.</p>
      <div className={styles.figure}>
        <img src="/images/help-search4.jpg" alt={title} />
      </div>
    </div>
  );
};

const HelpSearch5 = () => {
  const title = "Help - Search dataset - Step 5";
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setTitle(title));
  }, [dispatch, title]);
  return (
    <div>
      {HelpSearchLinks}
      <div className={styles.title}>Step 5</div>
      <p>
        The result page consists of details of the dataset.
        <br />
        Click the links in the "Download &amp; View" section to download the dataset and/or view 3D model on your browser.{" "}
      </p>
      <div className={styles.figure}>
        <img src="/images/help-search5.jpg" alt={title} />
      </div>
    </div>
  );
};

const HelpDataset = () => {
  const title = "Help - Downloadable dataset format";
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setTitle(title));
  }, [dispatch, title]);
  return (
    <section>
      <div className={styles.title}>Dataset Format</div>
      <div className={styles.figure}>
        <img src="/images/help-dataset.jpg" alt={title} />
      </div>
      <p>
        <span className={styles.legend}>First row:</span> The first row of the data file indicates the size of the imaging volume. The first, second and third column represent the width, height and depth of the volume, respectively.
      </p>
      <p>
        <span className={styles.legend}>Other rows:</span> Each row consists of the type (first column) and the position (second to fourth columns for x, y and z position) of the cell.
      </p>
    </section>
  );
};

const HelpViewer = () => {
  const title = "Help - 3D Viewer Manual";
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setTitle(title));
  }, [dispatch, title]);
  return (
    <section>
      <div className={styles.title}>3D Viewer Manual</div>
      <div className={styles.figure}>
        <img src="/images/help-viewer.jpg" alt={title} />
      </div>
      <p>
        <span className={styles.legend}>Rotate:</span> Left click and drag.
      </p>
      <p>
        <span className={styles.legend}>Move:</span> Right click and drag.
      </p>
      <p>
        <span className={styles.legend}>Zoom In/Out:</span> Use mouse wheel.
      </p>
    </section>
  );
};

const Help: React.FC = () => {
  return (
    <div className={styles.help}>
      <Routes>
        <Route index element={<HelpIndex />} />
        <Route path="search/1" element={<HelpSearch1 />} />
        <Route path="search/2" element={<HelpSearch2 />} />
        <Route path="search/3" element={<HelpSearch3 />} />
        <Route path="search/4" element={<HelpSearch4 />} />
        <Route path="search/5" element={<HelpSearch5 />} />
        <Route path="dataset" element={<HelpDataset />} />
        <Route path="viewer" element={<HelpViewer />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default Help;
