import React, { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import { setTitle } from "../page-title/pageTitleSlice";
import styles from "./NotFound.module.css";

const NotFound: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setTitle("Page Not Found"));
  }, [dispatch]);

  return <div className={styles.notFound}>Page not found.</div>;
};

export default NotFound;
