import React from "react";
import { Helmet } from "react-helmet-async";
import { useAppSelector } from "../../app/hooks";
import styles from "./PageTitle.module.css";
import { selectPageTitle } from "./pageTitleSlice";

const PageTitle: React.FC = () => {
  const title = useAppSelector(selectPageTitle);
  return (
    <>
      <Helmet>
        <title>{title} - CelLoc3D Server</title>
      </Helmet>
      <div className={styles.pageTitle}>{title}</div>
    </>
  );
};

export default PageTitle;
