import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../not-found/NotFound";
import SearchForm from "../search-form/SearchForm";
import SearchResults from "../search-results/SearchResults";
import styles from "./Search.module.css";

const Search: React.FC = () => {
  return (
    <div className={styles.search}>
      <Routes>
        <Route index element={<SearchForm />} />
        <Route path="results" element={<SearchResults />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default Search;
