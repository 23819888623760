import React, { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import JsonNews_ from "../../assets/news.json";
import { setTitle } from "../page-title/pageTitleSlice";
import styles from "./News.module.css";
const nl2br = require("react-nl2br");

interface IJsonNewsItem {
  date: string;
  desc: string;
}

type IJsonNews = IJsonNewsItem[];

const JsonNews: IJsonNews = JsonNews_;

const News: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setTitle("What's new"));
  }, [dispatch]);

  return (
    <div className={styles.news}>
      {JsonNews.map((item, key) => {
        return (
          <div className={styles.item} key={key}>
            <div className={styles.date}>{item.date}</div>
            <div className={styles.desc}>{nl2br(item.desc)}</div>
          </div>
        );
      })}
    </div>
  );
};

export default News;
