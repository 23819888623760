import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface PageTitleState {
  value: string;
}

const initialState: PageTitleState = {
  value: "",
};

const pageTitleSlice = createSlice({
  name: "pageTitle",
  initialState,
  reducers: {
    setTitle: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
  },
});

export const { setTitle } = pageTitleSlice.actions;
export const selectPageTitle = (state: RootState) => state.pageTitle.value;

export default pageTitleSlice.reducer;
