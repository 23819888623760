import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import NotFound from "../not-found/NotFound";

const CompatRedirect: React.FC = () => {
  const { pathname } = useLocation();
  switch (pathname) {
    case "/index.html":
      return <Navigate to="/" />;
    case "/Search.html":
      return <Navigate to="/search" />;
    case "/Help.html":
      return <Navigate to="/help" />;
    case "/Help_S1.html":
      return <Navigate to="/help/search/1" />;
    case "/Help_S2.html":
      return <Navigate to="/help/search/2" />;
    case "/Help_S3.html":
      return <Navigate to="/help/search/3" />;
    case "/Help_S4.html":
      return <Navigate to="/help/search/4" />;
    case "/Help_S5.html":
      return <Navigate to="/help/search/5" />;
    case "/Help_Dataset.html":
      return <Navigate to="/help/dataset" />;
    case "/Help_Viewer.html":
      return <Navigate to="/help/viewer" />;
    case "/Contact.html":
      return <Navigate to="/help/contact" />;
    case "/cgi-bin/SearchResult.cgi":
      return <Navigate to="/search/results" />;
    default:
      const match = pathname.match(/^\/DB\/TE\/(3D-)?(.+)\.html$/);
      if (match !== null) {
        const url = "/data/" + match[2] + (typeof match[1] !== "undefined" ? "/viewer" : "");
        return <Navigate to={url} />;
      }
      break;
  }
  return <NotFound />;
};

export default CompatRedirect;
