import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import DataUtils from "../data/DataUtils";
import Loading from "../loading/Loading";
import NotFound from "../not-found/NotFound";
import { setTitle } from "../page-title/pageTitleSlice";
import styles from "./DataDetail.module.css";

interface DetailDataJson {
  title: string;
  author: string;
  figure: {
    file: string;
    caption: string;
  };
  download: string;
  view: string;
  mouseLine: string;
  imagingArea: string;
  cellType: string[];
  postnatalDays: number;
  imagingVolumeSize: string;
  imagingDepth: string;
  journal: string;
  summary: string;
  releaseDate: string;
}

interface PropsFC {
  name: string;
}

interface Props extends PropsFC {
  dispatch: any;
}

interface State {
  name: string;
  data: DetailDataJson | null;
}

class DataDetail extends Component<Props, State> {
  isActive = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      name: props.name,
      data: null,
    };
  }

  componentDidMount() {
    const { name } = this.state;
    this.isActive = true;
    if (!DataUtils.exists(name)) {
      if (name !== "") {
        this.setState({ name: "" });
      }
      return;
    }
    axios
      .get(`/data/${name}.json`, { responseType: "json" })
      .then((response) => {
        if (this.isActive) {
          this.props.dispatch(setTitle(`DS: ${name}`));
          this.setState({ data: response.data as DetailDataJson });
        }
      })
      .catch((error) => {
        if (this.isActive) {
          this.setState({ name: "" });
        }
      });
  }

  componentWillUnmount() {
    this.isActive = false;
  }

  render() {
    const { name, data } = this.state;
    if (name === "") {
      return <NotFound />;
    }
    if (!data) {
      return <Loading />;
    }
    return (
      <section className={styles.detail}>
        <h4 className={styles.title}>{data.title}</h4>
        <div className={styles.author}>{data.author}</div>
        <figure>
          <img src={data.figure.file} alt={data.figure.caption} />
          <figcaption>{data.figure.caption}</figcaption>
        </figure>
        <dl>
          <dt>Download &amp; View</dt>
          <dd>
            <a href={data.download}>Text download</a>
          </dd>
          <dd>
            <Link to={data.view}>View 3D structure</Link>
          </dd>
        </dl>
        <dl>
          <dt>Mouse line</dt>
          <dd>{data.mouseLine}</dd>
        </dl>
        <dl>
          <dt>Imaging area</dt>
          <dd>{data.imagingArea}</dd>
        </dl>
        <dl>
          <dt>Cell type</dt>
          {data.cellType.map((value, key) => {
            return <dd key={key}>{value}</dd>;
          })}
        </dl>
        <dl>
          <dt>Postnatal days</dt>
          <dd>{data.postnatalDays}</dd>
        </dl>
        <dl>
          <dt>Imaging volume size</dt>
          <dd>{data.imagingVolumeSize}</dd>
        </dl>
        <dl>
          <dt>Imaging depth</dt>
          <dd>{data.imagingDepth}</dd>
        </dl>
        <dl>
          <dt>Journal</dt>
          <dd>{data.journal}</dd>
        </dl>
        <dl>
          <dt>Summary</dt>
          <dd dangerouslySetInnerHTML={{ __html: data.summary }}></dd>
        </dl>
        <dl>
          <dt>Release date</dt>
          <dd>{data.releaseDate}</dd>
        </dl>
      </section>
    );
  }
}

const DataDetailFC: React.FC<PropsFC> = (props) => {
  const dispatch = useAppDispatch();
  return <DataDetail dispatch={dispatch} name={props.name} />;
};

export default DataDetailFC;
