import React, { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import { setTitle } from "../page-title/pageTitleSlice";
import styles from "./Loading.module.css";

const Loading: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setTitle("Loading now..."));
  }, [dispatch]);

  return <div className={styles.loading}>Loading now...</div>;
};

export default Loading;
