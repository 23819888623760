import React from "react";
import ReactGA from "react-ga4";
import { BrowserRouter, NavLink, Route, Routes, useLocation } from "react-router-dom";
import styles from "./App.module.css";
import CompatRedirect from "./features/compat-redirect/CompatRedirect";
import Contact from "./features/contact/Contact";
import Data from "./features/data/Data";
import Help from "./features/help/Help";
import News from "./features/news/News";
import PageTitle from "./features/page-title/PageTitle";
import Search from "./features/search/Search";

const AppMain: React.FC = () => {
  const location = useLocation();

  React.useEffect(() => {
    ReactGA.send("pageview");
  }, [location]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <ul>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/search">Search</NavLink>
          </li>
          <li>
            <NavLink to="/help">Help</NavLink>
          </li>
          <li>
            <NavLink to="/contact">Contact</NavLink>
          </li>
        </ul>
      </div>
      <PageTitle />
      <div className={styles.main}>
        <Routes>
          <Route index element={<News />} />
          <Route path="search/*" element={<Search />} />
          <Route path="data/:name/*" element={<Data />} />
          <Route path="help/*" element={<Help />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<CompatRedirect />} />
        </Routes>
      </div>
      <div className={styles.footer}>&copy;2014 Laboratory for cortical circuit plasticity, RIKEN BSI.</div>
    </div>
  );
};

const App: React.FC = () => {
  ReactGA.initialize("G-T4L3SDCN6P");
  return (
    <BrowserRouter>
      <AppMain />
    </BrowserRouter>
  );
};

export default App;
