import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import DataDetail from "../data-detail/DataDetail";
import DataViewer from "../data-viewer/DataViewer";
import NotFound from "../not-found/NotFound";
import styles from "./Data.module.css";
import DataUtils from "./DataUtils";

const Data: React.FC = () => {
  const { name } = useParams<"name">();
  if (typeof name === "undefined" || !DataUtils.exists(name)) {
    return <NotFound />;
  }
  return (
    <div className={styles.data}>
      <Routes>
        <Route index element={<DataDetail name={name} />} />
        <Route path="viewer" element={<DataViewer name={name} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default Data;
