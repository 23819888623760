import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { SearchResults } from "../data/DataUtils";

export interface SearchResultsState {
  value: SearchResults;
}

const initialState: SearchResultsState = {
  value: [],
};

const searchResultsSlice = createSlice({
  name: "searchResults",
  initialState,
  reducers: {
    setResults: (state, action: PayloadAction<SearchResults>) => {
      state.value = action.payload;
    },
  },
});

export const { setResults } = searchResultsSlice.actions;
export const selectSearchResults = (state: RootState) => state.searchResults.value;

export default searchResultsSlice.reducer;
