import React, { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import { setTitle } from "../page-title/pageTitleSlice";
import styles from "./Contact.module.css";

const Contact: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setTitle("Contact Information"));
  }, [dispatch]);
  return (
    <div className={styles.contact}>
      <section className={styles.notice}>Notice: This site has been archived since May 2019 and is no longer updated.</section>
      <h4>CelLoc-3D is managed by Teppei Ebina and Tadaharu Tsumoto</h4>
      <p>
        Laboratory for cortical circuit plasticity
        <br />
        Brain Science Institute, RIKEN
        <br />
        2-1, Hirosawa, Wako, Saitama, 351-0198 Japan
      </p>
      <h4>E-mail:</h4>
      <p>
        teppei-ebina (at) brain.riken.jp
        <br />
        <span className={styles.note}>or</span>
        <br />
        tsumoto (at) brain.riken.jp
        <br />
        <span className={styles.note}>(change (at) to @)</span>
      </p>
    </div>
  );
};

export default Contact;
