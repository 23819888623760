import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import pageTitleReducer from "../features/page-title/pageTitleSlice";
import searchResultsReducer from "../features/search-results/searchResultsSlice";

export const store = configureStore({
  reducer: {
    pageTitle: pageTitleReducer,
    searchResults: searchResultsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
